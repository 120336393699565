import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PROSPECTION_URL, SUMMARY_URL } from '../apiConfig';
import './Prospeccion.css'; 
import ProspeccionModal from './ProspeccionModal'; 

const Prospeccion = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [saveLoading, setSaveLoading] = useState(false);
    const [studentData, setStudentData] = useState([]);
    const [startDate, setStartDate] = useState(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState(new Date());
    const [isModalOpen, setIsModalOpen] = useState(false);

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };

    // Obtener los datos de prospección del alumno
    useEffect(() => {
        fetchStudentData();
    }, [startDate, endDate]);

    const fetchStudentData = async () => {
        setSaveLoading(true);
        try {
            const response = await axios.get(SUMMARY_URL, {
                headers,
                params: {
                    start_date: startDate.toISOString().split('T')[0],
                    end_date: endDate.toISOString().split('T')[0],
                },
            });
            setStudentData(response.data);
        } catch (error) {
            console.error('Error fetching student data:', error);
        } finally {
            setSaveLoading(false);
        }
    };

    // Filtrar los datos por el rango de fechas seleccionado
    const handleFilterClick = () => {
        fetchStudentData();
    };

    // Obtener los datos de la última semana
    const handleFetchLastWeek = () => {
        setStartDate(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000));
        setEndDate(new Date());
        fetchStudentData();
    };

    // Obtener los datos del último mes
    const handleFetchLastMonth = () => {
        setStartDate(new Date(Date.now() - 29 * 24 * 60 * 60 * 1000));
        setEndDate(new Date());
        fetchStudentData();
    };

    // Calcular las sumatorias de las columnas
    const sumColumn = (key) => {
        return studentData.prospection.reduce((sum, row) => sum + (parseInt(row[key], 10) || 0), 0);
    };

    // Cerrar el modal después de guardar los datos
    const handleModalClose = () => {
        setIsModalOpen(false);
        // fetchStudentData(); // Actualizar los datos después de guardar en el modal
    };

    if (saveLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="prospection-container">
            <div className='main_div'>
                <h1>Prospección</h1>
                <div className='date-container'>
                    <button className="btn btn-primary mt-3" onClick={() => setIsModalOpen(true)}>Agregar o modificar datos</button>
                    {isModalOpen && <ProspeccionModal 
                    onClose={handleModalClose} 
                    startDate={startDate}   // 
                    endDate={endDate}
                    studentData={studentData}
                    setStudentData={setStudentData}       />} 
                </div>
                <div className='date-container'>
                    <div className="me-3">
                        <label>Fecha Inicio:</label>
                        <input
                            type="date"
                            className="form-control"
                            value={startDate.toISOString().split('T')[0]}
                            onChange={(e) => setStartDate(new Date(e.target.value))}
                        />
                    </div>
                    <div className="me-3">
                        <label>Fecha Fin:</label>
                        <input
                            type="date"
                            className="form-control"
                            value={endDate.toISOString().split('T')[0]}
                            onChange={(e) => setEndDate(new Date(e.target.value))}
                        />
                    </div>
                </div>
                    <div className='date-container'>

                    <div className="align-self-end">
                        <button onClick={handleFilterClick} className="btn btn-primary">Filtrar</button>
                    </div>
                        <div className="align-self-end">
                            <button onClick={handleFetchLastWeek} className="btn btn-secondary ms-2">Última Semana</button>
                        </div>
                        <div className="align-self-end">
                            <button onClick={handleFetchLastMonth} className="btn btn-secondary ms-2">Último Mes</button>
                    </div>
                </div>
                {/* Verificar si studentData tiene la propiedad prospection */}
                {studentData.prospection && studentData.prospection.length > 0 ? (
                    <table className="table table-striped table-hover table-bordered mt-3">
                        <thead className="table-success">
                            <tr>
                                <th>Fecha</th>
                                <th>1ra Parte Enviada</th>
                                <th>2da Parte Enviada</th>
                                <th>2da Parte Respondida</th>
                                <th>Reuniones Realizadas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentData.prospection.map((data, index) => (
                                <tr key={index}>
                                    <td>{data.date}</td>
                                    <td>{data.contactos_parte_1_enviada}</td>
                                    <td>{data.contactos_parte_2_enviada}</td>
                                    <td>{data.contactos_parte_2_respondida}</td>
                                    <td>{data.reuniones_realizadas}</td>
                                </tr>
                            ))}
                            <tr className="table-secondary">
                                <td>Total</td>
                                <td>{sumColumn('contactos_parte_1_enviada')}</td>
                                <td>{sumColumn('contactos_parte_2_enviada')}</td>
                                <td>{sumColumn('contactos_parte_2_respondida')}</td>
                                <td>{sumColumn('reuniones_realizadas')}</td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <p className="text-danger mt-3">No se encontraron datos para el rango de fechas seleccionado.</p>
                )}
            </div>
        </div>
    );
};

export default Prospeccion;
