// src/apiConfig.js import { LOGIN_URL } from '../apiConfig';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'http://localhost:8000';


// ENDPOINTS PARA GESTIÓN DE USUARIOS
export const LOGIN_URL = `${API_BASE_URL}/api/users/login/`;
export const LOGOUT_URL = `${API_BASE_URL}/api/users/logout/`;
export const REGISTER_URL = `${API_BASE_URL}/api/users/register/`;

// ENDPOINTS PARA FUNCIONALIDAD DE APP ESTUDIANTES
export const SUMMARY_URL = `${API_BASE_URL}/api/tracking/summary/`;
export const RESUMEN_URL = `${API_BASE_URL}/api/tracking/resumen/`;
export const PROSPECTION_URL = `${API_BASE_URL}/api/tracking/prospection/`;
export const PERSONAL_URL = `${API_BASE_URL}/api/tracking/personal/`;
export const DASHBOARD_URL = `${API_BASE_URL}/api/tracking/dashboard/`;
export const CLIENTS_URL = `${API_BASE_URL}/api/tracking/clients/`;
export const CLIENT_LIST_CREATE_URL = `${API_BASE_URL}/api/tracking/clients/<int:pk>/`;
export const CLIENTS_STATS_URL = `${API_BASE_URL}/api/tracking/clients/stats/`;
export const VIDEO_URL = `${API_BASE_URL}/api/video/`;

//ENDPOINTS PARA SUPERVISORES
export const USERS_URL = `${API_BASE_URL}/api/users/`;
export const STUDENT_DATA_URL = `${API_BASE_URL}/api/tracking/student-data/`;
export const UPDATE_USER_URL = `${API_BASE_URL}/api/users/update/<int:user_id>/`;
export const CREATE_USER_URL = `${API_BASE_URL}/api/tracking/create-student-data/`;

export const CREATE_ATTENDANCE_URL = `${API_BASE_URL}/api/attendance/<int:user_id>/`;
export const EDIT_ATTENDANCE_URL = `${API_BASE_URL}/api/attendance/<int:user_id>/<int:id>/`;
