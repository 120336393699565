import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { LOGOUT_URL } from '../apiConfig';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';
import './Prospeccion.css'; // Asegúrate de que este archivo contiene los estilos necesarios
// Importación de JavaScript de Bootstrap y Popper.js
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const Navbar = () => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };

    const handleLogout = async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('selectedDate');
        localStorage.removeItem('isSupervisor');
        try {
            await axios.post(LOGOUT_URL, null, { headers });
        } catch (error) {
            console.error('Error logging out:', error);
        } finally {
            navigate('/');
        }
    };

    const confirmLogout = () => {
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleConfirmLogout = () => {
        handleLogout();
        setShowModal(false);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <NavLink className="navbar-brand" >
                        La Milkiforma
                    </NavLink>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item mx-3">
                                <NavLink className="nav-link" to="/resumen">
                                    Resumen
                                </NavLink>
                            </li>
                            <li className="nav-item mx-3">
                                <NavLink className="nav-link" to="/prospeccion">
                                    Prospección
                                </NavLink>
                            </li>
                            {/* Puedes agregar más elementos aquí si es necesario */}
                        </ul>
                        <button className="btn btn-outline-danger btn-logout" onClick={confirmLogout}>
                            <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
                            Logout
                        </button>
                    </div>
                </div>
            </nav>

            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Estás seguro que deseas cerrar sesión?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button variant="danger" onClick={handleConfirmLogout}>
                        Cerrar Sesión
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Navbar;
