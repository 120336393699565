import React, { useEffect, useState } from 'react';
import ClientModal from './ClientModal';
import axios from 'axios';
import { RESUMEN_URL, SUMMARY_URL } from '../apiConfig';
import 'bootstrap/dist/css/bootstrap.min.css';

const Resumen = () => {
  const [streak, setStreak] = useState(0);
  const [totalPayment, setTotalPayment] = useState(0);
  const [totalClients, setTotalClients] = useState(0);
  const [script1ResponseRate, setScript1ResponseRate] = useState(0);
  const [script2ResponseRate, setScript2ResponseRate] = useState(0);
  const [closingRate, setClosingRate] = useState(0);
  const [closedClientRate, setclosedClientRate] = useState(0);
  const [followUpRate, setFollowUpRate] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fullName, setFullName] = useState('');
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado de error

  // Función para obtener los datos del resumen
  const fetchUpdatedData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('No autorizado');
        return;
      }

      const headers = { Authorization: `Token ${token}` };
      const response = await axios.get(SUMMARY_URL, { headers });
      const updatedData = response.data;

      // Actualiza el estado con los datos recibidos
      setStreak(updatedData.streak);
      setTotalPayment(updatedData.total_payment);
      setTotalClients(updatedData.total_clients);
      setScript1ResponseRate(updatedData.script1_response_rate);
      setScript2ResponseRate(updatedData.script2_response_rate);
      setClosingRate(updatedData.closing_rate);
      setclosedClientRate(updatedData.closed_clients_rate);
      setFollowUpRate(updatedData.follow_up_rate);
      setFullName(`${updatedData.first_name} ${updatedData.last_name}`);
    } catch (error) {
      setError('Error al obtener los datos del resumen.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUpdatedData(); // Llama a la función para obtener los datos una vez montado el componente
  }, []);

  const handleClientChange = () => {
    setIsModalOpen(false);
    fetchUpdatedData(); // Vuelve a cargar los datos después de gestionar clientes
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Cargando...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center mt-5 text-danger">
        {error}
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <h1>Salud de mi negocio</h1>
      </div>
      <div className="card p-4">
        <h2 className="h2 resumen-nombre">
          Bienvenido {fullName}, llevas {streak} {streak === 1 ? 'día' : 'días'} de racha{streak >= 5 ? '!!!' : '.'}
        </h2>

        <div className="card p-4">
          <h2 className="h3 mt-4">Resumen de facturación</h2>
          <p className="mb-2">Total de pagos mensuales: ${totalPayment}</p>
          <p>Total de clientes: {totalClients}</p>
          <button className="btn btn-primary mt-3" onClick={() => setIsModalOpen(true)}>
            Gestionar Clientes
          </button>
          {isModalOpen && <ClientModal onClose={handleClientChange} />}
        </div>

        <div className="card p-4">
          <h2 className="h3 mt-4">Estadísticas de prospección</h2>
          <p>Respuestas a script 1: {script1ResponseRate ? parseFloat(script1ResponseRate).toFixed(2) : '0.00'}%</p>
          <p>Respuestas a script 2: {script2ResponseRate ? parseFloat(script2ResponseRate).toFixed(2) : '0.00'}%</p>
          <p>Tasa de cierre (Clientes / Contactados): {closingRate ? parseFloat(closingRate).toFixed(2) : '0.00'}%</p>
          <p>Tasa de cierre (Clientes / Reuniones): {closedClientRate ? parseFloat(closedClientRate).toFixed(2) : '0.00'}%</p>
          <p>Tasa de seguimiento: {followUpRate ? parseFloat(followUpRate).toFixed(2) : '0.00'}%</p>
        </div>
      </div>
    </div>
  );
};

export default Resumen;
