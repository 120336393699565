import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { USERS_URL, REGISTER_URL, UPDATE_USER_URL } from '../apiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { TailSpin } from 'react-loader-spinner';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOption, setSortOption] = useState('name_asc');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterRole, setFilterRole] = useState('estudiante');
    const [filterStatus, setFilterStatus] = useState('active'); // Estado inicial como "Activos"
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [message, setMessage] = useState(null); 
    const [messageType, setMessageType] = useState(''); 
    const [formState, setFormState] = useState({
        last_name: '',
        first_name: '',
        username: '',
        email: '',
        password: '',
        role: 'Estudiante',
        allowed: true,
    });

    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');

    const token = localStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        filterAndSortUsers();
    }, [searchTerm, sortOption, filterRole, filterStatus, startDate, endDate]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await axios.get(USERS_URL, { headers });
            console.log(response.data)
            setUsers(response.data);
            setFilteredUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const filterAndSortUsers = () => {
        let tempUsers = [...users];

        if (searchTerm) {
            tempUsers = tempUsers.filter(user =>
                `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        if (filterRole) {
            tempUsers = tempUsers.filter(user => 
                filterRole === 'supervisor' ? user.is_supervisor == 'Supervisión' : user.is_supervisor == 'Estudiantes');
            };

        if (filterStatus) {
            tempUsers = tempUsers.filter(user =>
                filterStatus === 'active' ? user.allowed : !user.allowed
            );
        }

        if (startDate) {
            tempUsers = tempUsers.filter(user => new Date(user.date_joined) >= new Date(startDate));
        }
        if (endDate) {
            tempUsers = tempUsers.filter(user => new Date(user.date_joined) <= new Date(endDate));
        }

        switch (sortOption) {
            case 'name_asc':
                tempUsers.sort((a, b) => a.first_name.localeCompare(b.first_name));
                break;
            case 'name_desc':
                tempUsers.sort((a, b) => b.first_name.localeCompare(a.first_name));
                break;
            case 'date_newest':
                tempUsers.sort((a, b) => new Date(b.date_joined) - new Date(a.date_joined));
                break;
            case 'date_oldest':
                tempUsers.sort((a, b) => new Date(a.date_joined) - new Date(b.date_joined));
                break;
            default:
                break;
        }
        console.log(tempUsers)

        setFilteredUsers(tempUsers);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
    };

    const handleRoleFilterChange = (e) => {
        setFilterRole(e.target.value);
    };

    const handleStatusFilterChange = (e) => {
        setFilterStatus(e.target.value);
    };

    const handleDateChange = (e, isStart) => {
        const formattedDate = e.target.value;
        if (isStart) setStartDate(formattedDate);
        else setEndDate(formattedDate);
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setFormState({
            last_name: user.last_name,
            first_name: user.first_name,
            username: user.username,
            email: user.email,
            role: user.is_supervisor,
            allowed: user.allowed,
        });
        setShowForm(true);
    };
                                                                                                        
    const handleDeleteUser = async (userId) => {
        if (window.confirm('¿Estás seguro de que deseas establecer a este usuario como inactivo?')) {
            setLoading(true);
            try {
                await axios.put(`${UPDATE_USER_URL.replace('<int:user_id>', userId)}`, { allowed: false }, { headers });
                setMessage('Usuario inactivado exitosamente');
                setMessageType('success');
                fetchUsers();
            } catch (error) {
                console.error('Error setting user as inactive:', error);
                setMessage('Error al inactivar el usuario');
                setMessageType('danger');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleFormChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormState({
            ...formState,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (selectedUser) {
                // console.log(formState)
                await axios.put(`${UPDATE_USER_URL.replace('<int:user_id>', selectedUser.id)}`, formState, { headers });
                setMessage('Usuario actualizado exitosamente');
            } else {
                console.warn(formState)
                const response = await axios.post(REGISTER_URL, formState, { headers });
                setMessage('Usuario creado exitosamente' + response);
            }
            setMessageType('success');
            fetchUsers();
        } catch (error) {
            console.error('Error saving user:', error);
            setMessage('Error al guardar el usuario');
            setMessageType('danger');
        } finally {
            setLoading(false);
        }
    };

    const handleCreateNewUser = () => {
        setSelectedUser(null);
        setFormState({
            last_name: '',
            first_name: '',
            username: '',
            email: '',
            role: 'Estudiante',
            allowed: true,
        });
        setShowForm(true);
    };
    
    const toggleShowPassword = () => {
        setShowPassword(!showPassword); 
    };
    // Función para validar la contraseña
    const validatePassword = (password) => {
        let error = '';
        if (password.length < 8) {
        error = 'La contraseña debe tener al menos 8 caracteres.';
        } else if (!isNaN(password)) {
        error = 'La contraseña no puede ser completamente numérica.';
        }
        return error;
    };

    // Manejador del cambio de la contraseña
    const handlePasswordChange = (e) => {
        const { value } = e.target;

        // Validar la contraseña mientras el usuario escribe
        const error = validatePassword(value);
        setPasswordError(error);

        // Actualizar el estado del formulario
        handleFormChange(e);
    };
    
    return (
        <div className="container mt-4">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1>Gestión de Usuarios</h1>
                <button className="btn btn-outline-success" onClick={handleCreateNewUser}>
                    <FontAwesomeIcon icon={faPlus} /> Crear Usuario
                </button>
            </div>

            {message && (
                <Alert variant={messageType} onClose={() => setMessage(null)} dismissible>
                    {message}
                </Alert>
            )}

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Buscar por nombre"
                    value={searchTerm}
                    onChange={handleSearch}
                    disabled={loading}
                />
                <div className="d-flex justify-content-between">
                    <div>
                        <label>Ordenar por:</label>
                        <select className="form-select" value={sortOption} onChange={handleSortChange} disabled={loading}>
                            <option value="name_asc">Nombres (A-Z)</option>
                            <option value="name_desc">Nombres (Z-A)</option>
                            <option value="date_newest">Más nuevos primero</option>
                            <option value="date_oldest">Más viejos primero</option>
                        </select>
                    </div>
                    <div>
                        <label>Filtrar por rol:</label>
                        <select className="form-select" value={filterRole} onChange={handleRoleFilterChange} disabled={loading}>
                            <option value="">Todos</option>
                            <option value="supervisor">Supervisión</option>
                            <option value="estudiante">Estudiantes</option>
                        </select>
                    </div>
                    <div>
                        <label>Filtrar por estado:</label>
                        <select className="form-select" value={filterStatus} onChange={handleStatusFilterChange} disabled={loading}>
                            <option value="active">Activos</option>
                            <option value="inactive">Inactivos</option>
                        </select>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>
                        <label>Fecha de inicio:</label>
                        <input type="date" className="form-control" value={startDate} onChange={(e) => handleDateChange(e, true)} disabled={loading} />
                    </div>
                    <div>
                        <label>Fecha de fin:</label>
                        <input type="date" className="form-control" value={endDate} onChange={(e) => handleDateChange(e, false)} disabled={loading} />
                    </div>
                </div>
            </div>

            {loading ? (
                <div className="text-center">
                    <TailSpin height={50} width={50} color="#000" ariaLabel="loading" />
                </div>
            ) : (
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Nombres</th>
                                <th>Correo</th>
                                <th>Rol</th>
                                <th>Estado</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map(user => (
                                <tr key={user.id}>
                                    <td>{user.first_name} {user.last_name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.is_supervisor == 'Supervisión' ? 'Supervisión' : 'Estudiante'}</td>
                                    <td>{user.allowed ? 'Activo' : 'Inactivo'}</td>
                                    <td>
                                    <button className="btn btn-outline-primary me-2" onClick={() => handleEditUser(user)}>
                                        <FontAwesomeIcon icon={faEdit} /> Editar
                                    </button>
                                    
                                        <button className="btn btn-outline-danger" onClick={() => handleDeleteUser(user.id)}>
                                            <FontAwesomeIcon icon={faTrash} /> Inactivar
                                    </button>
                                    
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {showForm && (
                <Modal show={showForm} onHide={() => setShowForm(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedUser ? 'Editar Usuario' : 'Crear Usuario'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleFormSubmit}>
                            <Form.Group className="mb-3" controlId="formFirstName">
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="first_name"
                                    value={formState.first_name}
                                    onChange={handleFormChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formLastName">
                                <Form.Label>Apellido</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="last_name"
                                    value={formState.last_name}
                                    onChange={handleFormChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formUsername">
                                <Form.Label>Nombre de Usuario</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    value={formState.username}
                                    onChange={handleFormChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label>Correo</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={formState.email}
                                    onChange={handleFormChange}
                                    required
                                />
                            </Form.Group>
                            {selectedUser ? 
                            <Form.Group className="mb-3" controlId="formPassword">
                                <Form.Label>Contraseña</Form.Label>
                                <div className="input-group">
                                    <Form.Control
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        value={formState.password}
                                        onChange={handlePasswordChange} // Cambié esta línea para usar la validación
                                        isInvalid={!!passwordError} // Marca el campo como inválido si hay un error
                                    />
                                    <Button
                                        variant="outline-secondary"
                                        onClick={toggleShowPassword}
                                    >
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </Button>
                                    <Form.Control.Feedback type="invalid">
                                        {passwordError}
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                            : '' }
                            <Form.Group className="mb-3" controlId="formRole">
                                <Form.Label>Rol</Form.Label>
                                <Form.Select
                                    name="role"
                                    value={formState.role}
                                    onChange={handleFormChange}
                                    required
                                >
                                    <option value="Estudiante">Estudiante</option>
                                    <option value="Supervisión">Supervisión</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formAllowed">
                                <Form.Check
                                    type="checkbox"
                                    name="allowed"
                                    label="Permitir acceso"
                                    checked={formState.allowed}
                                    onChange={handleFormChange}
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                {selectedUser ? 'Guardar Cambios' : 'Crear Usuario'}
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            )}
        </div>
    );
};

export default UserManagement;
