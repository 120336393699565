import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CalendarSelector = ({ initialDate, onDateChange }) => {
    const [selectedDate, setSelectedDate] = useState(initialDate);

    useEffect(() => {
        setSelectedDate(initialDate); // Actualizar el estado interno si initialDate cambia
    }, [initialDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        onDateChange(date); // Propagar la fecha seleccionada al componente padre
    };

    return (
        <div>
            <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
        </div>
    );
};

export default CalendarSelector;
