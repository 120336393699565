import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { USERS_URL, STUDENT_DATA_URL } from '../apiConfig';
import { TailSpin } from 'react-loader-spinner';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import AttendanceModal from './AttendanceModal'; 

const SupervisorResumen = () => {
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [studentData, setStudentData] = useState([]);
    const [startDate, setStartDate] = useState(new Date(Date.now() - 6 * 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState(new Date());
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [isViewingDetails, setIsViewingDetails] = useState(false);
    const token = localStorage.getItem('token');
    const headers = { Authorization: `Token ${token}` };
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchStudents = async () => {
            setLoading(true);
            try {
                const response = await axios.get(USERS_URL, { headers });
                let studentsFiltered = [] 

                for (let student of response.data){
                    if (student.allowed === true){
                        studentsFiltered.push(student)
                    } 
                }
                setStudents(studentsFiltered);
            } catch (error) {
                console.error('Error fetching students', error);
            } finally {
                setLoading(false);
            }
        };
        fetchStudents();
    }, []);

    const fetchStudentData = async (selectedStudent, start_date = startDate, end_date = endDate) => {
        setLoading(true);
        try {
            console.log()
            const response = await axios.get(STUDENT_DATA_URL, {
                headers,
                params: {
                    student_id: selectedStudent,
                    start_date: start_date.toISOString().split('T')[0],
                    end_date: end_date.toISOString().split('T')[0],
                },
            });

            const { prospection } = response.data;
            const combinedData = combineDataByDate(prospection,start_date, end_date);
            setStudentData(combinedData);
            setIsViewingDetails(true);
        } catch (error) {
            console.error('Error fetching student data:', error);
        } finally {
            setLoading(false);
        }
    };
    // // Cerrar el modal después de guardar los datos
    // const handleModalClose = () => {
    //     setIsModalOpen(false);
    // }

    const handleDateChange = (newStartDate, newEndDate) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
    };

    const handleFetchLastWeek = () => {
        const newStartDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
        const newEndDate = new Date();
        handleDateChange(newStartDate, newEndDate);
        setTimeout(() => fetchStudentData(selectedStudent, newStartDate, newEndDate), 0); // Espera a que se actualicen las fechas antes de hacer la solicitud
    };

    const handleFetchLastMonth = () => {
        const newStartDate = new Date(Date.now() - 29 * 24 * 60 * 60 * 1000);
        const newEndDate = new Date();
        handleDateChange(newStartDate, newEndDate);
        setTimeout(() => fetchStudentData(selectedStudent, newStartDate, newEndDate), 0); // Espera a que se actualicen las fechas antes de hacer la solicitud
    };
    
    const handleFilterClick = () => {
        fetchStudentData(selectedStudent); // Realiza la solicitud manualmente solo cuando se hace clic en el botón Filtrar
    };

    const combineDataByDate = (prospection, startDate = startDate, endDate = endDate) => {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dateMap = new Map();

        prospection.forEach((item) => {
            dateMap.set(item.date, {
                fecha: item.date,
                contactos_parte_1_enviada: item.contactos_parte_1_enviada || 0,
                contactos_parte_2_enviada: item.contactos_parte_2_enviada || 0,
                reuniones_realizadas: item.reuniones_realizadas || 0,
            });
        });

        const result = [];
        for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            const dateStr = dt.toISOString().split('T')[0];
            if (!dateMap.has(dateStr)) {
                dateMap.set(dateStr, {
                    fecha: dateStr,
                    contactos_parte_1_enviada: 0,
                    contactos_parte_2_enviada: 0,
                    reuniones_realizadas: 0,
                });
            }
            result.push(dateMap.get(dateStr));
        }

        return result.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
    };

    const handleViewDetails = (studentId) => {
        setSelectedStudent(studentId);
        fetchStudentData(studentId) 
        };

    const handleBackToStudentList = () => {
        setIsViewingDetails(false);
    };


    const sumColumn = (key) => {
        return studentData.reduce((sum, row) => sum + (parseInt(row[key], 10) || 0), 0);
    };
    
    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
                <h1 className="mb-0">Supervisión - Resumen de Alumnos</h1>
            </div>
            {loading && (
                <div className="d-flex justify-content-center mt-3">
                    <TailSpin color="#007bff" height={50} width={50} />
                </div>
            )}
            {!isViewingDetails ? (
                <div className="card p-3">
                    <div className="mb-3">
                        <h3>Alumnos</h3>
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Buscar por nombre"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <ul className="list-group">
                            {students
                                .filter((student) =>
                                    `${student.first_name} ${student.last_name}`
                                        .toLowerCase()
                                        .includes(searchTerm.toLowerCase())
                                )
                                .map((student) => (
                                    <li key={student.id} className="list-group-item d-flex justify-content-between align-items-center">
                                        {student.first_name} {student.last_name}
                                        <button className="btn btn-sm btn-outline-primary" onClick={() => handleViewDetails(student.id)}>
                                            <FontAwesomeIcon icon={faEye} /> Ver detalles
                                        </button>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            ) : (
                <div>
                    <button className="btn btn-outline-primary mb-3" onClick={handleBackToStudentList}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Volver a la lista de alumnos
                    </button>

                    <button className="btn btn-primary mt-3" onClick={() => setIsModalOpen(true)}>
                        Asistencia de alumnos</button>
                        {isModalOpen && (
                            <AttendanceModal 
                                show={isModalOpen} 
                                handleClose={() => setIsModalOpen(false)} 
                                student={students.find((s) => s.id === selectedStudent)}
                            />
                        )}
                    <h3>Datos de {students.find((s) => s.id === selectedStudent)?.first_name} {students.find((s) => s.id === selectedStudent)?.last_name}</h3>
                    <div className="d-flex mb-3">
                <div className="me-3">
                    <label>Fecha Inicio:</label>
                    <input
                        type="date"
                        className="form-control"
                        value={startDate.toISOString().split('T')[0]}
                        onChange={(e) => setStartDate(new Date(e.target.value))}
                    />
                </div>
                <div className="me-3">
                    <label>Fecha Fin:</label>
                    <input
                        type="date"
                        className="form-control"
                        value={endDate.toISOString().split('T')[0]}
                        onChange={(e) => setEndDate(new Date(e.target.value))}
                    />
                </div>
                <div className="align-self-end">
                    <button onClick={handleFilterClick} className="btn btn-primary">Filtrar</button>
                </div>
                <div className="align-self-end">
                    <button onClick={handleFetchLastWeek} className="btn btn-secondary ms-2">Última Semana</button>
                </div>
                <div className="align-self-end">
                    <button onClick={handleFetchLastMonth} className="btn btn-secondary ms-2">Último Mes</button>
                </div>
            </div>

                    {studentData.length > 0 ? (
                        <table className="table table-striped table-hover table-bordered">
                            <thead className="table-success">
                                <tr>
                                    <th>Fecha</th>
                                    <th>1ra Parte Enviada</th>
                                    <th>2da Parte Enviada</th>
                                    <th>Reuniones Realizadas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {studentData.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.fecha}</td>
                                        <td>{data.contactos_parte_1_enviada}</td>
                                        <td>{data.contactos_parte_2_enviada}</td>
                                        <td>{data.reuniones_realizadas}</td>
                                    </tr>
                                ))}
                                <tr className="table-secondary">
                                    <td>Total</td>
                                    <td>{sumColumn('contactos_parte_1_enviada')}</td>
                                    <td>{sumColumn('contactos_parte_2_enviada')}</td>
                                    <td>{sumColumn('reuniones_realizadas')}</td>
                                </tr>
                            </tbody>
                        </table>
                    ) : (
                        <p className="text-danger">No se encontraron datos para este estudiante en el rango de fechas seleccionado.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default SupervisorResumen;
